:root {
  --toastify-toast-width: 235px;
}

.Toastify__toast {
  top: 20px;
  max-width: 235px;
  border-radius: 20px;
  backdrop-filter: blur(14px);
  padding: 16px 20px;
  text-align: center;
}

.Toastify__toast-body {
  padding: 0;
  font-weight: 500;
}